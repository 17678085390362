import "./countdown.js";

const init = function() {
	$('.js-countdown-wrapper:not(.js-countdown-wrapper-done)').each(function() {
		var wrapper = $(this);

		var dateTo = wrapper.find('.js-countdown').attr('data-to');

		var elements = {
			countdown: wrapper.find('.js-countdown'),
			days: wrapper.find('.js-countdown-days'),
			hours: wrapper.find('.js-countdown-hours'),
			minutes: wrapper.find('.js-countdown-minutes'),
			seconds: wrapper.find('.js-countdown-seconds'),
			countdownFinished: wrapper.find('.js-countdown-done')
		};

		var countdownText = {
			days: wrapper.find('.js-countdown-days-text'),
			hours: wrapper.find('.js-countdown-hours-text'),
			minutes: wrapper.find('.js-countdown-minutes-text'),
			seconds: wrapper.find('.js-countdown-seconds-text')
		};

		var units = countdown.DAYS | countdown.HOURS | countdown.MINUTES | countdown.SECONDS;

		var timerId = countdown(dateTo,
			function(timespan) {
				if (timespan.end > timespan.start) {
					elements.countdown.hide();
					elements.countdownFinished.show();
					return;
				}

				elements.days.text(timespan.days);
				elements.hours.text(addLeadingZeros(timespan.hours));
				elements.minutes.text(addLeadingZeros(timespan.minutes));
				elements.seconds.text(addLeadingZeros(timespan.seconds));

				setCountdownText(timespan.days, countdownText.days, "day", "days");
				setCountdownText(timespan.hours, countdownText.hours, "hour", "hours");
				setCountdownText(timespan.minutes, countdownText.minutes, "min", "mins");
				setCountdownText(timespan.seconds, countdownText.seconds, "sec", "secs");

				hideClockElementsIfZero(timespan.days, elements.days, countdownText.days);
				hideClockElementsIfZero(timespan.hours, elements.hours, countdownText.hours);
				hideClockElementsIfZero(timespan.minutes, elements.minutes, countdownText.minutes);
			},
			units);

		wrapper.addClass('js-countdown-wrapper-done');
	});
}

const addLeadingZeros = function(t) {
	var s = t.toString();
	while (s.length < 2) {
		s = "0" + s;
	}
	return s;
}

const setCountdownText = function(currentValue, textElement, textIfOne, textIfMultiple) {
	if (currentValue === 1) {
		textElement.text(textIfOne);
	} else {
		textElement.text(textIfMultiple);
	}
}

const hideClockElementsIfZero = function(currentValue, timeElement, textElement) {
	if (currentValue === 0) {
		timeElement.hide();
		textElement.hide();
	}
}


export default init;
